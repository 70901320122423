import React, { useState, useEffect } from "react";
import "./App.css";
import PlateCalculator from "./components/PlateCalculator";
import OneRepMaxCalculator from "./components/OneRepMaxCalculator";
import PRTracker from "./components/PRTracker";
import FeedbackForm from "./components/FeedbackForm";
import DonationSection from "./components/DonationSection";

function App() {
  const [targetWeight, setTargetWeight] = useState("");
  const [warmupSeries, setWarmupSeries] = useState([]);
  const [barWeight, setBarWeight] = useState(
    localStorage.getItem("barWeight") || "45"
  );
  const [showPlateCalculator, setShowPlateCalculator] = useState(false);
  const [availablePlates, setAvailablePlates] = useState([]);
  const [selectedPlates, setSelectedPlates] = useState({});
  const [unit, setUnit] = useState(localStorage.getItem("unit") || "lbs");
  const [method, setMethod] = useState(
    localStorage.getItem("method") || "default"
  );
  const [customWarmupSeries, setCustomWarmupSeries] = useState(
    JSON.parse(localStorage.getItem("customWarmupSeries")) || []
  );
  const [isCustomWeight, setIsCustomWeight] = useState(false);
  const [activeCalculator, setActiveCalculator] = useState("warmup");
  const [prs, setPRs] = useState(JSON.parse(localStorage.getItem("prs")) || []);

  useEffect(() => {
    localStorage.setItem("method", method);
    localStorage.setItem("unit", unit);
    localStorage.setItem(
      "customWarmupSeries",
      JSON.stringify(customWarmupSeries)
    );
    localStorage.setItem("barWeight", barWeight);
    localStorage.setItem("prs", JSON.stringify(prs));

    const getAvailablePlates = () => {
      return unit === "kg"
        ? [1, 1.25, 2.5, 5, 10, 20]
        : [2.5, 5, 10, 25, 35, 45];
    };

    const availablePlates = getAvailablePlates();
    setAvailablePlates(availablePlates);

    const initialSelectedPlates = availablePlates.reduce((acc, plate) => {
      acc[plate] = true;
      return acc;
    }, {});

    setSelectedPlates(initialSelectedPlates);

    const calculateWarmupSeries = () => {
      let warmupPercentages;
      let reps;

      const roundToNearestPlate = (weight, plates) => {
        let minDiff = Number.MAX_VALUE;
        let roundedWeight = weight;

        for (const plate of plates) {
          const times = Math.floor(weight / (2 * plate));
          const currentWeight = times * 2 * plate;

          const diff = Math.abs(currentWeight - weight);
          if (diff < minDiff) {
            minDiff = diff;
            roundedWeight = currentWeight;
          }
        }

        return roundedWeight;
      };

      if (method === "custom") {
        return customWarmupSeries.map((set, index) => {
          let weight = roundToNearestPlate(
            (targetWeight * set.percentage) / 100,
            availablePlates
          );
          return {
            set: index + 1,
            weight: weight,
            reps: set.reps,
          };
        });
      } else if (method === "oneRepMax") {
        warmupPercentages = [0.4, 0.6, 0.7, 0.8, 0.9, 1];
        reps = [8, 5, 3, 1, 1, 1];
      } else if (method === "GSLP") {
        warmupPercentages = [0.25, 0.25, 0.4, 0.575, 0.75, 1];
        reps = [5, 5, 5, 3, 2];
      } else if (method === "StartingStrength") {
        warmupPercentages = [0, 0, 0.45, 0.65, 0.85, 1];
        reps = [5, 5, 5, 3, 2];
      } else {
        warmupPercentages = [0.4, 0.6, 0.7, 1];
        reps = [10, 6, 3];
      }

      const warmupSeries = warmupPercentages.map((percentage, index) => {
        let weight = roundToNearestPlate(
          targetWeight * percentage,
          availablePlates
        );
        return {
          set: index + 1,
          weight: weight,
          reps: reps[index],
        };
      });

      return warmupSeries;
    };
    const calculatedWarmupSeries = calculateWarmupSeries();
    setWarmupSeries(calculatedWarmupSeries);
  }, [targetWeight, unit, method, customWarmupSeries, barWeight, prs]);

  const handlePlateCheckbox = (e) => {
    const { value, checked } = e.target;
    setSelectedPlates((prevSelectedPlates) => ({
      ...prevSelectedPlates,
      [value]: checked,
    }));
  };

  const addCustomWarmupSet = (e) => {
    e.preventDefault();
    setCustomWarmupSeries([...customWarmupSeries, { percentage: 0, reps: 0 }]);
  };

  const updateCustomWarmupSet = (index, field, value) => {
    const updatedWarmupSeries = [...customWarmupSeries];
    updatedWarmupSeries[index][field] = parseFloat(value);
    setCustomWarmupSeries(updatedWarmupSeries);
  };

  const removeCustomWarmupSet = (index) => {
    const updatedWarmupSeries = customWarmupSeries.filter(
      (_, i) => i !== index
    );
    setCustomWarmupSeries(updatedWarmupSeries);
  };

  const handleUnitChange = (e) => {
    const newUnit = e.target.value;
    setUnit(newUnit);

    if (newUnit === "kg") {
      if (barWeight === "45") {
        setBarWeight("20");
      } else if (barWeight === "35") {
        setBarWeight("15");
      }
    } else if (newUnit === "lbs") {
      if (barWeight === "20") {
        setBarWeight("45");
      } else if (barWeight === "15") {
        setBarWeight("35");
      }
    }
  };

  const addPR = (pr) => {
    setPRs([...prs, pr]);
  };

  const removePR = (index) => {
    setPRs(prs.filter((_, i) => i !== index));
  };

  return (
    <div className="App">
      <h1>Fitness Calculators</h1>
      <div className="calculator-toggle">
        <button
          onClick={() => setActiveCalculator("warmup")}
          className={activeCalculator === "warmup" ? "active" : ""}
        >
          Warmup Calculator
        </button>
        <button
          onClick={() => setActiveCalculator("oneRepMax")}
          className={activeCalculator === "oneRepMax" ? "active" : ""}
        >
          1RM Calculator
        </button>
        <button
          onClick={() => setActiveCalculator("prTracker")}
          className={activeCalculator === "prTracker" ? "active" : ""}
        >
          PR Tracker
        </button>
      </div>
      {activeCalculator === "warmup" && (
        <>
          <h2>Warmup Calculator</h2>
          <form>
            <label htmlFor="targetWeight">
              Target Weight(bar included):
              <input
                className="target-weight"
                id="targetWeight"
                type="number"
                value={targetWeight}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!isNaN(value) && value >= 0) {
                    setTargetWeight(value);
                  }
                }}
              />
            </label>
            <label htmlFor="unit">
              Unit:
              <select id="unit" value={unit} onChange={handleUnitChange}>
                <option value="lbs">lbs</option>
                <option value="kg">kg</option>
              </select>
            </label>
            <label htmlFor="method">
              Warm up Method
              <select
                id="method"
                value={method}
                onChange={(e) => setMethod(e.target.value)}
              >
                <option value="default">Default</option>
                <option value="custom">Custom</option>
                <option value="GSLP">Greyskull LP</option>
                <option value="StartingStrength">Starting Strength</option>
                <option value="oneRepMax">One rep max</option>
              </select>
            </label>
            {method === "custom" && (
              <div className="custom-warmup-series">
                <strong>Custom Warm up Series</strong>
                <div className="custom-warmup-controls">
                  <button
                    className="add-warmup-set-btn"
                    onClick={(e) => addCustomWarmupSet(e)}
                  >
                    Add Warm-up Set
                  </button>
                  {customWarmupSeries.map((set, index) => (
                    <div
                      key={index}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <label
                        htmlFor={`percentage-${index}`}
                        style={{ marginRight: "5px" }}
                      >
                        Percentage:
                      </label>
                      <input
                        id={`percentage-${index}`}
                        type="number"
                        value={set.percentage}
                        onChange={(e) =>
                          updateCustomWarmupSet(
                            index,
                            "percentage",
                            e.target.value
                          )
                        }
                        placeholder="Percentage"
                        style={{ marginRight: "5px" }}
                      />
                      <span>%</span>
                      <label
                        htmlFor={`reps-${index}`}
                        style={{ marginLeft: "10px", marginRight: "5px" }}
                      >
                        Reps:
                      </label>
                      <input
                        id={`reps-${index}`}
                        type="number"
                        value={set.reps}
                        onChange={(e) =>
                          updateCustomWarmupSet(index, "reps", e.target.value)
                        }
                        placeholder="Reps"
                        style={{ marginRight: "5px" }}
                      />
                      <button onClick={() => removeCustomWarmupSet(index)}>
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </form>
          <h2>Warm up Series:</h2>
          <div className="warmup-series-container">
            {warmupSeries.map((set, index) => {
              const repText = set.reps === 1 ? "rep" : "reps";
              return (
                <div key={index} className="warmup-set">
                  Set {set.set}: {set.weight} {unit} x {set.reps} {repText}
                  {showPlateCalculator && (
                    <div className="plates-container">
                      <PlateCalculator
                        weight={set.weight}
                        unit={unit}
                        barWeight={barWeight}
                        selectedPlates={selectedPlates}
                      />
                      {"   "}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <button onClick={() => setShowPlateCalculator(!showPlateCalculator)}>
            Plate Calculator
          </button>
          {showPlateCalculator && (
            <>
              <form>
                <label htmlFor="barWeight">
                  Bar Weight:
                  <select
                    data-testid="barWeight-select"
                    className="select-bar-weight"
                    id="barWeight"
                    value={isCustomWeight ? "custom" : barWeight}
                    onChange={(e) => {
                      if (e.target.value === "custom") {
                        setIsCustomWeight(true);
                      } else {
                        setIsCustomWeight(false);
                        setBarWeight(e.target.value);
                      }
                    }}
                  >
                    {unit === "lbs" ? (
                      <>
                        <option value="45">Standard (45 lbs)</option>
                        <option value="35">Women's (35 lbs)</option>
                      </>
                    ) : (
                      <>
                        <option value="20">Standard (20 kg)</option>
                        <option value="15">Women's (15 kg)</option>
                      </>
                    )}
                    <option value="custom">Custom</option>
                  </select>
                  {isCustomWeight && (
                    <input
                      className="small-input"
                      id="customBarWeight"
                      type="number"
                      placeholder="Enter custom weight"
                      value={barWeight}
                      onChange={(e) => setBarWeight(e.target.value)}
                    />
                  )}
                </label>
              </form>
              <div>
                <strong>Available plates:</strong>
                <div className="plates-selection">
                  {availablePlates.map((plate, index) => (
                    <label key={index} className="plate-checkbox">
                      <input
                        type="checkbox"
                        value={plate}
                        checked={selectedPlates[plate] || false}
                        onChange={handlePlateCheckbox}
                      />
                      {plate}
                      {unit}
                    </label>
                  ))}
                </div>
              </div>
            </>
          )}
        </>
      )}
      {activeCalculator === "oneRepMax" && <OneRepMaxCalculator />}
      {activeCalculator === "prTracker" && (
        <PRTracker prs={prs} addPR={addPR} removePR={removePR} />
      )}
      <FeedbackForm />
      {/* <DonateButton /> */}
      <DonationSection />
    </div>
  );
}

export default App;
