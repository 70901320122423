import React, { useState } from "react";
import "./PRTracker.css";

const PRTracker = ({ prs, addPR, removePR }) => {
  const [exercise, setExercise] = useState("");
  const [weight, setWeight] = useState("");
  const [unit, setUnit] = useState("lbs");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (exercise && weight) {
      addPR({
        exercise,
        weight: parseFloat(weight),
        unit,
        date: new Date().toISOString(),
      });
      setExercise("");
      setWeight("");
    }
  };

  return (
    <div className="pr-tracker">
      <h2>Personal Records</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={exercise}
          onChange={(e) => setExercise(e.target.value)}
          placeholder="Exercise"
          required
        />
        <input
          type="number"
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
          placeholder="Weight"
          required
        />
        <select value={unit} onChange={(e) => setUnit(e.target.value)}>
          <option value="lbs">lbs</option>
          <option value="kg">kg</option>
        </select>
        <button type="submit">Add PR</button>
      </form>
      <ul>
        {prs.map((pr, index) => (
          <li key={index}>
            {pr.exercise}: {pr.weight} {pr.unit} -{" "}
            {new Date(pr.date).toLocaleDateString()}
            <button onClick={() => removePR(index)}>Remove</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PRTracker;
