import React from "react";
import PropTypes from "prop-types";

function PlateCalculator({ weight, unit, barWeight, selectedPlates }) {
  const plateCalculator = (weight) => {
    const plates = Object.keys(selectedPlates)
      .filter((plate) => selectedPlates[plate])
      .map(Number)
      .sort((a, b) => b - a);
    const plateCounts = Array(plates.length).fill(0);
    let remainingWeight = weight - (barWeight ? parseFloat(barWeight) : 0);

    remainingWeight /= 2;

    for (let i = 0; i < plates.length; i++) {
      while (remainingWeight >= plates[i]) {
        remainingWeight -= plates[i];
        plateCounts[i]++;
      }
    }

    const plateElements = plateCounts.map((count, index) => {
      if (count > 0) {
        return (
          <span
            className={`plate plate-${plates[index]
              .toString()
              .replace(".", "_")}`}
            key={`plate-${plates[index]}`}
          >
            {count} x {plates[index]}
            {unit}{" "}
          </span>
        );
      } else {
        return null;
      }
    });

    return plateElements.filter((element) => element !== null);
  };

  return (
    <span>
      {weight > 0 && plateCalculator(weight).map((plate) => plate)}
    </span>
  );
}

PlateCalculator.propTypes = {
  weight: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  barWeight: PropTypes.string,
  selectedPlates: PropTypes.object.isRequired,
};

export default React.memo(PlateCalculator);
