import React, { useState } from 'react';
import DonateButton from './DonateButton';

function DonationSection() {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={`donation-section ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <button className="donation-toggle" onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? 'Hide' : 'Support Us'}
      </button>
      {isExpanded && (
        <>
          <div className="donation-text">
            <h3>Help a fellow gymbro out!</h3>
            <p>Your donation keeps this site running and my creatine stocked. Every bit helps!</p>
          </div>
          <DonateButton />
          <img 
            src="/raposa-esmola.jpg" 
            alt="Funny fox asking for donation" 
            width="100"
            height="100"
            className="donation-image"
          />
        </>
      )}
    </div>
  );
}

export default DonationSection;
