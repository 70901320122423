import React, { useState } from "react";
import "./OneRepMaxCalculator.css"; // We'll create this file for the styles

const OneRepMaxCalculator = () => {
  const [weight, setWeight] = useState("");
  const [reps, setReps] = useState("1");
  const [unit, setUnit] = useState("lbs");
  const [oneRepMax, setOneRepMax] = useState(null);

  const calculateOneRepMax = () => {
    if (weight && reps) {
      const calculatedOneRepMax = weight * (36 / (37 - reps));
      setOneRepMax(Math.round(calculatedOneRepMax * 100) / 100);
    }
  };

  const handleUnitChange = (e) => {
    const newUnit = e.target.value;
    setUnit(newUnit);
    if (oneRepMax) {
      setOneRepMax(
        newUnit === "lbs" ? oneRepMax * 2.20462 : oneRepMax / 2.20462
      );
    }
  };

  const convertWeight = (value) => {
    return unit === "lbs" ? value / 2.20462 : value * 2.20462;
  };

  return (
    <div className="one-rep-max-calculator">
      <h2>One Rep Max Calculator</h2>
      <div className="input-group">
        <label htmlFor="weight">Weight lifted:</label>
        <div className="input-with-select">
          <input
            type="number"
            id="weight"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
          <select id="unit" value={unit} onChange={handleUnitChange}>
            <option value="lbs">lbs</option>
            <option value="kg">kg</option>
          </select>
        </div>
      </div>
      <div className="input-group">
        <label htmlFor="reps">Reps performed:</label>
        <input
          type="number"
          id="reps"
          value={reps}
          onChange={(e) => setReps(e.target.value)}
          min="1"
        />
      </div>
      <button onClick={calculateOneRepMax}>Calculate 1RM</button>
      {oneRepMax && (
        <div className="result">
          <p>Estimated One Rep Max:</p>
          <p>
            {oneRepMax.toFixed(2)} {unit}
          </p>
          <p>
            {convertWeight(oneRepMax).toFixed(2)}{" "}
            {unit === "lbs" ? "kg" : "lbs"}
          </p>
        </div>
      )}
    </div>
  );
};

export default OneRepMaxCalculator;
